/** @jsx jsx */
import { jsx } from "theme-ui"
import { makeStyles } from '@material-ui/core/styles'
import { useState } from "react"
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
    root: {
        width: 345,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    result: {
        width: '300px',
    },
    iconButton: {
        padding: 10,
    },
}));

const initRecipeInfo = {
    recipeName: '',
    recipeUrl: '',
    recipePoint: '',
    recipeTags: ''
}

const Row = () => {
    const [recipeInfo, setRecipeInfo] = useState(initRecipeInfo)
    const [recipeRow, setRecipeRow] = useState('')
    const [copied, setCopied] = useState(false)

    const classes = useStyles()

    const changeRecipeInfo = (e: any) => {
        setRecipeInfo({
            ...recipeInfo,
            [e.target.name]: e.target.value
        })
    }

    const yyyymmdd = (date: Date) => {
        const yyyy = `${date.getFullYear() * 10000}`
        const mm = `${(date.getMonth() + 1)}`
        const dd = `${date.getDate()}`
        return `${yyyy.slice(2, 4)}/${mm.length == 1 ? '0' + mm : mm}/${dd.length == 1 ? '0' + dd : dd}`
    }

    const createRow = (e: any) => {
        const dateStr = yyyymmdd(new Date())
        const tagsWithSlash = recipeInfo.recipeTags.match(/\s/g) ? recipeInfo.recipeTags.split(/\s/g).join('/') : recipeInfo.recipeTags
        const row = `${dateStr},${recipeInfo.recipeName},${recipeInfo.recipeUrl},${recipeInfo.recipePoint},${tagsWithSlash}`
        setRecipeRow(row)
    }

    const copyRow = (e: any) => {
        navigator.clipboard.writeText(recipeRow)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }

    return (
        <Grid container justify="center" spacing={2}>
            <Grid container justify="center" style={{ padding: 1 }}>
                <h1>create row</h1>
            </Grid>
            <Grid container justify="center" style={{ padding: 10 }}>
                <Paper>
                    <Grid container justify="center" style={{ padding: 10 }}>
                        <InputBase
                            className={classes.input}
                            placeholder="レシピ名を入力"
                            inputProps={{ 'aria-label': 'レシピ名を入力' }}
                            name="recipeName"
                            onChange={changeRecipeInfo}
                        />
                    </Grid>
                </Paper>
            </Grid>
            <Grid container justify="center" style={{ padding: 10 }}>
                <Paper>
                    <Grid container justify="center" style={{ padding: 10 }}>
                        <InputBase
                            className={classes.input}
                            placeholder="レシピURLを入力"
                            inputProps={{ 'aria-label': 'レシピURLを入力' }}
                            name="recipeUrl"
                            onChange={changeRecipeInfo}
                        />
                    </Grid>
                </Paper>
            </Grid>
            <Grid container justify="center" style={{ padding: 10 }}>
                <Paper>
                    <Grid container justify="center" style={{ padding: 10 }}>
                        <InputBase
                            className={classes.input}
                            placeholder="ポイントを入力"
                            inputProps={{ 'aria-label': 'ポイントを入力' }}
                            type="number"
                            name="recipePoint"
                            onChange={changeRecipeInfo}
                        />
                    </Grid>
                </Paper>
            </Grid>
            <Grid container justify="center" style={{ padding: 10 }}>
                <Paper>
                    <Grid container justify="center" style={{ padding: 10 }}>
                        <InputBase
                            className={classes.input}
                            placeholder="タグを入力"
                            inputProps={{ 'aria-label': 'スペース区切りでタグを入力' }}
                            name="recipeTags"
                            onChange={changeRecipeInfo}
                        />
                    </Grid>
                </Paper>
            </Grid>
            <Grid container justify="center" style={{ padding: 10 }}>
                <Button variant="contained" onClick={createRow}>Create Row</Button>
            </Grid>
            <Grid container justify="center" style={{ padding: 30 }}>
                <Paper>
                    <InputBase
                        className={classes.result}
                        value={recipeRow}
                        placeholder=""
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <IconButton className={classes.iconButton} aria-label="createRow" onClick={copyRow}>
                        <FileCopyIcon />
                    </IconButton>
                </Paper>
            </Grid>
            {copied ? <div>copied!</div>: null}
            <Grid container justify="center" style={{ padding: 30 }}>
                <a href="https://github.com/nmrmasakazu/masalifetokyo/blob/main/src/data/recipes.csv">Commit to the Repository</a>
            </Grid>
            <Grid container justify="center">
                <a href="../../">Home</a>
            </Grid>
        </Grid>
    )
}

export default Row
